.wrapSearchThread {
    vertical-align: middle;
    padding-top: 50px;
    input {
        margin-top: 50px;
        font-weight: bold;
        margin-left: 7%;
        font-size: 12px;
        padding-left: 12px;
        padding-right: 12px;
        min-height: 45px;
        width: 80%;
        color: #36383b;
        border-radius: 4px;
        border: 1px solid #dfe9eb;
        outline: none;
    }
    span.msg {
        margin-left: 7%;
        color: red;
        display: block;
        font-size: 12px;
        margin-bottom: 5px;
    }

    div.search-success-title {
        flex-direction: row;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-self: center;
        align-items: center;
        vertical-align: middle;
    }
    .button-link-home {
        cursor: pointer;
        font-size: 18px;
        color: #36383b;
        font-weight: bold;
        margin: auto;
        width: 230px;
        height: 50px;
        background-color: #ffe033;
        border: #ffe86a;
        border-radius: 30px;
        border-color: #eef780 #dbbd16 #dbbd16;
        border-style: solid;
        border-width: 1px 0px 3px;
    }
    .button-back-list {
        cursor: pointer;
        font-size: 18px;
        color: #36383b;
        font-weight: bold;
        margin: auto;
        width: 230px;
        height: 50px;
        background-color: #ffffff;
        border: #ffffff;
        border-radius: 30px;
        border-color: #ffffff #abb3ba #abb3ba;
        border-style: solid;
        border-width: 1px 0px 3px;
    }
}
.submit-button-wrap {
    margin-top: 50px !important;
    text-align: center;
    font-size: 17px;
    color: #36383b;
    font-weight: bold;
}
.submit-button-wrap2 {
    margin-top: 20px !important;
    text-align: center;
    font-size: 17px;
    color: #36383b;
    font-weight: bold;
}

.submit-button-wrap:hover {
    cursor: pointer;
}

.submit-button-wrap2:hover {
    cursor: pointer;
}

.theme-text {
    margin-left: 5px;
    text-align: center;
    color: #36383b;
    font-weight: bold;
    font-size: 17px;
}

.theme-text:hover {
    cursor: default;
}

.theme-submit-button {
    cursor: pointer;
    font-size: 18px;
    color: #36383b;
    font-weight: bold;
    margin: auto;
    width: 230px;
    height: 50px;
    background-color: #ffe033;
    border: #ffe86a;
    border-radius: 30px;
    border-color: #eef780 #dbbd16 #dbbd16;
    border-style: solid;
    border-width: 1px 0px 3px;
}
