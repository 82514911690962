#changeAvatar {
    width: 375px;
    height: 675px;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    background-color: #f4f9fa;
    margin: 0px auto;
    position: relative;
    display: flex;
    flex-direction: column;
    header {
        width: 100%;
        height: 40pt;
        background-color: #fff;
        border-bottom: 1px solid #d8d8d8;
        div {
            width: 30%;
            float: left;
            line-height: 50px;
            height: 50px;
            font-size: 12px;
            font-weight: 300;
            margin: 0px;
            padding: 0px;
        }
        div.back {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img{
                margin-left: 20px;
                cursor: pointer;
            }
            span {
                font-size: 15px;
                color: #000000;
            }
            span:hover {
                cursor: pointer;
            }
        }
        div.right {
            text-align: right;
            padding-top: 5pt;
        }
        h3 {
            width: 40%;
            float: left;
            line-height: 50px;
            height: 50px;
            font-size: 12px;
            font-weight: 300;
            margin: 0px;
            padding: 0px;
            text-align: center;
            font-size: 18px;
            color: #000000;
        }
        h3:hover {
            cursor: default;
        }
    }
    div.mainContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        div.modal-container {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 615px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #827e7e80;
            flex-direction: column;
            z-index: 99;
            div.modal-body {
                div.modal-header{
                    min-height: 40px;
                    height: 40px;
                    width: 100%;
                    background-color: white;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 30px;
                    color: #6f6f6f;
                    svg {
                        padding-left: 10px;
                        padding-right: 10px;
                        cursor: pointer;
                    }
                }
                max-height: 415px;
                max-width: 375px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: grey;
            }
        }
        div.avatarContainer {
            margin-top: 30px;
            margin-bottom: 50px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            div.imageContainer {
                img {
                    background: white;
                    width: 110px;
                    height: 110px;
                    border-radius: 50%;
                    border-width: 1px;
                    border-color: #f0f0f0;
                    border-style: solid;
                    object-fit: cover;
                }
            }
            span {
                font-size: 12px;
                color: red;
                margin-left: 16px;
            }
        }

        div.buttonContainer {
            margin-bottom: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            div.button {
                width: 65%;
                border-radius: 25px;
                height: 50px;
                background-color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    font-size: 14px;
                    font-weight: 300;
                    color: #36383b;
                }
                span:hover {
                    cursor: pointer;
                }
            }
            div.button:hover {
                cursor: pointer;
            }
            label.uploadLabel {
                width: 65%;
                border-radius: 25px;
                height: 50px;
                background-color: #ffffff;
                display: flex;
                align-items: center;
                border-width: 0px;
                justify-content: center;
                font-size: 14px;
                font-weight: 300;
                color: #36383b;
            }
            label:hover {
                cursor: pointer;
            }
            #upload-photo {
                opacity: 0;
                position: absolute;
                z-index: -1;
            }
            div.buttonConfirm {
                margin-top: 30px;
                width: 65%;
                border-radius: 25px;
                height: 50px;
                background-color: #ffe033;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 2px #dbbd16;
                span {
                    font-size: 18px;
                    font-weight: 600;
                    color: #36383b;
                }
                span:hover {
                    cursor: pointer;
                }
            }
            div.buttonConfirm:hover {
                cursor: pointer;
            }
        }
    }
}
