#blood-container {
    width: 375px;
    height: 667px;
    overflow: scroll;
    .back-container{
        border-bottom: 1px solid #d8d8d8;
        width: 100%;
        background-color: white;
        height: 50px;
    }
    .my-back{
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 10px;
        cursor: pointer;
        position: absolute;
        z-index: 2;
    }
    span{
        padding: 10px;
    }
    button {
        border-width: 0px;
    }
}
