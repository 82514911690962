#history-topic {
    cursor: pointer;
    .main {
        height: 70px;
        margin: 15px 10px 10px 10px;;
        background-color: #fdffce;
        padding-right: 10px;
        padding-left: 5px;
        border-radius: 5px;
        display: flex;
        overflow: hidden;
        font-weight: bold;
        box-shadow: 3px 3px #a6d837;
    }

    .child-main {
        flex: 1;
        padding-left: 5px;
        padding-top: 5px;
        span{
            font-size: 12px;
            color: #19d419;
            font-weight: bold;
            padding-right: 5px;
        }
        img{
            width: 9px;
            padding-right: 5px;
        }
    }

    .wrap-count-down {
        flex: 1;
        margin-bottom: 5px;
        cursor: default;
        position: relative;
        .time-count-down{
            position: absolute;
            right: 2px;
            top: 0px;
            display: flex;
            cursor: default;
            img{
                margin-right: 5px;
            }
            span{
                color: #88A744;
                font-size: 10px;
                font-weight: 600;
            }
        }
        .time-count-down-alarm{
            position: absolute;
            background: #FD5454;
            border-radius: 14px;
            height: 18px;
            right: 2px;
            padding: 2px 8px;
            top: 0px;
            display: flex;
            cursor: default;
            img{
                margin-top: -2px;
                margin-right: 5px;
            }
            span{
                color: #fff;
                font-size: 10px;
                font-weight: 600;
            }
        }
    }

    .topic {
        flex: 1;
        display: flex;
        flex-direction: row;
        p {
            width: 100%;
            margin-right: 10px;
            margin-left: 5px;
            font-size: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        img.img-arrow{
            margin-right: 10px;
        }
    }
}
