#count-down-reply-list {
    padding: 8px 7px 8px 7px;
    background-color: #F3F877;
    display: flex;
    border-radius: 34px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #5F5F5F;
    font-size: 13px;
    cursor: default;
    
}

#count-down-reply-list-red {
    padding: 8px 7px 8px 7px;
    background-color: #FD5454;
    display: flex;
    border-radius: 34px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: default;
}