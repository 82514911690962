.href {
    text-decoration: none;
}

.item-my-create {
    background-color: #FDFFCE;
    display: flex;
    flex-direction: row;
    padding: 4px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 5px;
    min-height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-color: #dfe9eb #A6D837 #A6D837;
    border-style: solid;
    border-width:  0px 3px 3px 0px;
    .item-name {
        margin-left: 15px;
        width: 85%;
        font-size: 16px ;
        font-weight: bold;
        justify-content: center;
        align-self: center;
        align-items: center;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
    }
    .item-count {
        margin-right: -15px;
        width: 40%;
        justify-content: center;
        align-self: center;
        align-items: center;
        
        .item-call {
            margin-left: 3px;
            margin-right: 8px;
            font-size: 12px ;
            font-weight: bold;
        }
        .img-icon-call{
            margin-left: 10x;
        }
        .img-icon-chat{
            margin-left: 10px;
        }
        .item-chat {
            margin-left: 5px;
            margin-right: 10px;
            font-size: 12px ;
            font-weight: bold;
        }
    }
}
.item-other-create {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    padding: 4px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 5px;
    min-height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-color: #dfe9eb #A6D837 #A6D837;
    border-style: solid;
    border-width:  0px 3px 3px 0px;
    .item-name {
        margin-left: 15px;
        width: 85%;
        font-size: 16px ;
        font-weight: bold;
        justify-content: center;
        align-self: center;
        align-items: center;
        vertical-align: middle;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
    }
    .item-count {
        margin-right: -15px;
        width: 40%;
        justify-content: center;
        align-self: center;
        align-items: center;
        
        .item-call {
            margin-left: 3px;
            margin-right: 8px;
            font-size: 12px ;
            font-weight: bold;
        }
        .img-icon-call{
            margin-left: 10x;
        }
        .img-icon-chat{
            margin-left: 10px;
        }
        .item-chat {
            margin-left: 5px;
            margin-right: 10px;
            font-size: 12px ;
            font-weight: bold;
        }
    }
}


