div.detail-user {
    padding-bottom: 10px;
    header {
        text-align: center;
        padding-top: 80px;
        position: relative;
        clear: both;
        > img {
            width: 93px;
            height: 93px;
            margin-top: -10px;
            border: 1px solid #f0f0f0;
            border-radius: 100%;
            position: absolute;
            top: -15px;
            left: 121px;
            background-color: white;
            cursor: default;
        }
        p.name {
            font-size: 13px;
            font-weight: 600;
            color: #36383b;
            margin-bottom: 10px;
            cursor: default;
        }
        p.detail {
            font-size: 11px;
            font-weight: 300;
            color: #36383b;
            cursor: default;
        }
        div.detail-history-add-friend {
            position: absolute;
            display: flex;
            flex-direction: column;
            right: 25px;
            top: 15px;
            cursor: pointer;
            img {
                width: 55px;
                height: 55px;
            }
            span {
                font-size: 13px;
                color: #36383b;
                font-weight: 600;
            }
        }
    }
    .detail {
        margin: 0px auto;
        text-align: center;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            .detail-price {
                text-align: right;
                padding-top: 5px;
                p {
                    text-align: left;
                    cursor: default;
                }
                img {
                    width: 23px;
                    height: 23px;
                    margin-right: 5px;
                }
            }
            .detail-price-content {
                float: left;
                text-align: left;
                cursor: default;
            }
            .detail-pricex {
                text-align: right;
                margin-left: 10px;
                cursor: default;
            }
            div {
                p,
                span {
                    font-size: 11px;
                    font-weight: 300;
                    color: #36383b;
                    cursor: default;
                }
            }
        }
        padding-bottom: 16px;
    }
    .comment {
        padding: 0px 20px 0px 20px;
        div {
            span.x {
                font-size: 11px;
                background: #afafaf;
                padding: 6px 10px;
                border-radius: 12px;
                width: 70px;
                display: block;
                height: 13px;
                line-height: 13px;
                float: left;
                color: #ffffff;
                text-align: center;
                cursor: default;
            }
            a {
                float: right;
                margin-top: -6px;
                img {
                    width: 40px;
                    height: 40px;
                }
                &.msg-detail {
                    font-size: 10px;
                    text-decoration: none;
                    margin-top: 10px;
                    color: #88a744;
                    img {
                        width: 12px;
                        height: 12px;
                        margin: 3px;
                    }
                    span {
                        margin-top: 1px;
                        display: block;
                        float: right;
                        cursor: default;
                    }
                }
            }
            clear: both;
            height: 30px;
        }
        p.commenttext {
            font-size: 12px;
            color: #36383b;
            margin-bottom: 20px;
            text-align: left;
            font-weight: 300;
            line-height: 16px;
            overflow-y: scroll;
            max-height: 48px;
            white-space: pre-wrap;
            cursor: default;
        }
        div.detailTopic {
            overflow-y: scroll;
            height: auto;
            min-height: 48px;
            max-height: 128px;
            p {
                font-size: 12px;
                color: #36383b;
                text-align: left;
                font-weight: 300;
                line-height: 15px;
                white-space: pre-line;
                height: auto;
                cursor: default;
            }
            p:nth-child(2) {
                margin-top: 15px;
            }
        }
    }
    div.history-call {
        clear: both;
        padding: 10px 0px 10px 0px;
        margin-bottom: 30px;
        img {
            width: 30px;
        }
        div {
            float: left;
        }
        div:nth-child(1) {
            width: 37%;
            text-align: right;
        }
        div:nth-child(2) {
            width: 63%;
            text-align: center;
        }
    }
}
