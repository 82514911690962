#history-converstation {
    height: 70px;
    margin: 15px 10px 10px 10px;
    background-color: white;
    padding-right: 10px;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
    align-items: center;
    font-weight: bold;
    box-shadow: 3px 3px #a6d837;
    .avata-container{
        display: flex;        
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80px;
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        p {
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 80px;
        }
    }
    #avata {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .main {
        flex: 1;
        height: 100%;
        width: 400px;
        margin-top: 2px;
        background-color: white;
        .title{
            margin-top: 5px;
            width: 100%;
            height: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            span{
                font-size: 11px;
                padding-right: 5px;
                color: #19d419;
            }
            img {
                width: 13px;
                height: 13px;
                padding-right: 5px;
            }
        }
    }
    div.conversation {
        display: flex;
        align-items: flex-end;
        margin-left: 5px;
        text-overflow: ellipsis;
        width: 240px;
        margin-top: 1px;
        height: 20px;
        .request-call {
            z-index: 101;
        }
        p {
            width: 100%;
            margin-right: 10px;
            margin-left: 5px;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        img {
            position: absolute;
            height: 16px;
            width: 16px;
            top: 18px;
            right: 8px;
        }
        div.historyTitle {
            position: absolute;
            width: 250px;
            height: 40px;
            cursor: pointer;
            top: 20px;
            display: flex;
            align-items: center;
            z-index: 100;
            p {
                width: 250px;
                text-justify: auto;
                margin-right: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    .countdown {
        flex: 1;
        height: 100%;
        cursor: default;
        position: relative;
        width: 260px;
        .time-count-down {
            position: absolute;
            right: 2px;
            top: 4px;
            display: flex;
            cursor: default;
            img {
                margin-right: 5px;
            }
            span {
                color: #88a744;
                font-size: 10px;
                font-weight: 600;
            }
        }
        .time-count-down-alarm {
            position: absolute;
            background: #fd5454;
            border-radius: 14px;
            height: 18px;
            right: 2px;
            padding: 2px 8px;
            top: -2px;
            display: flex;
            cursor: default;
            img {
                margin-top: -2px;
                margin-right: 5px;
            }
            span {
                color: #fff;
                font-size: 10px;
                font-weight: 600;
            }
        }
    }
}
