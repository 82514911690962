.filter-theme-popup-wrap {
    vertical-align: middle;
    padding-top: 35px;
}

.filter-theme-popup {
    margin: auto;
      width: 80% !important;
      text-align: center;
      height: 400px;
      background-color: #FFFFFF;
      border: 2px solid #A6D837;
      border-radius: 10px;
}

.filter-theme-popup-icon {
    padding-top: 40px;
}


.filter-theme-popup-text1 {
    font-size: 14px;
    padding-top: 40px;
}

.filter-theme-popup-text2 {
    font-size: 14px;
    padding-top: 20px;	
}

.filter-theme-popup-button-wrap {
    padding-top: 40px;
}

.filter-theme-popup-button {
    cursor: pointer;
    font-size: 18px;
    margin: auto;
    width: 230px;
    height: 50px;
    border-radius: 30px;
    background-color: #FFE033;
    border: #FFE033;
    border-color: #FFE033 #dbbd16 #dbbd16;
    border-style: solid;
    border-width: 1px 0px 3px;
}