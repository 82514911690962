div.unread-msg {
    position: absolute;
    left: -25px;
    top: 5px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    background-color: #fc0000;
    justify-content: center;
    align-items: center;
    span {
        text-align: center;
        font-size: 10px;
        color: #f4f9fa;
    }
}
#reply-list-item {
    height: 72px;
    margin: 10px 0px 10px 0px;
    background-color: white;
    display: flex;
    overflow: hidden;
    align-items: center;
    #avata {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;
        border-width: 1px;
        border-color: #f0f0f0;
        border-style: solid;
        cursor: pointer;
        img {
            width: 54px;
            height: 54px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .main {
        height: 50px;
        width: 240px;
        margin-left: 15px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        .user-name {
            font-weight: bold;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #36383b;
        }
        div.unreadBadge {
            position: absolute;
            right: 5px;
            top: 0px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            background-color: #fc0000;
            justify-content: center;
            align-items: center;
            span {
                text-align: center;
                font-size: 10px;
                color: #f4f9fa;
            }
        }
        .message {
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-lines: 1;
            color: #aaafb7;
        }

        .unread-message {
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-lines: 1;
            color: #36383b;
        }
    }
    i.callable {
        padding: 10px;
        color: #a6d837;
        font-size: 22px;
    }
    i.uncallable {
        padding: 10px;
        color: #abb3ba;
        font-size: 22px;
    }
}
