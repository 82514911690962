body {
    margin: 0px;
    padding: 0px;
    background: #fafafa;
    font-family: 'Hiragino Kaku Gothic ProN';
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 5px;
}
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #999;
}

.custom-ui {
    &.custom-ui-chat {
        margin-top: -314px;
    }
    width: 260px;
    text-align: right;
    padding: 5px 20px;
    background: #fff;
    box-shadow: 0 2px 75px rgba(0, 0, 0, 0.23);
    border-radius: 2px;
    color: #fff;
    h1 {
        font-size: 16px;
        color: #000;
        text-align: center;
    }
    button {
        margin-top: 10px;
        background: #fff;
        border: 0px;
        font-weight: bolder;
        margin-bottom: 10px;
        font-size: 14px;
        outline: none;
    }
}

#tutorial {
    width: 375px;
    position: relative;
    height: 667px;
    margin: 0px auto;
    padding: 0px 0px;
    box-sizing: border-box;
    background-image: linear-gradient(#EEF780, #A5EB79);
    text-align: center;
    .my-node-enter {
        opacity: 0;
    }
    .my-node-enter-active {
        opacity: 1;
        transition: opacity 200ms;
    }
    .my-node-exit {
        opacity: 1;
    }
    .my-node-exit-active {
        opacity: 0;
        transition: opacity 200ms;
    }
    .header {
        width: 100%;
        height: 100px;
    }
    .content {
        width: 70%;
        height: 467px;
        margin-left: 15%;
        text-align: left;
        span {
            font-size: 18px;
        }
        img {
            width: 100%;
            margin-top: 30px;
            background: transparent;
        }
    }
    .footer {
        width: 100%;
        height: 100px;
        align-items: center;
        display: flex;
        flex-direction: row;
        div {
            width: 33%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
            justify-content: center;
        }
        button {
            background: transparent;
            border-width: 0;
        }
    }
}
