#reply-list {
    width: 375px;
    height: 587px;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    background-color: white;
    margin: 0px auto;
    position: relative;

    #main-container {
        position: relative;
        background: #000;
        display: flex;
        flex-direction: column;
    }
    
    #header {
        width: 100%;
        height: 40pt;
        background: #fff;
        border-bottom: 1px solid #d8d8d8;
    }
    
    #content {
        width: 100%;
        height: 547px;
        box-sizing: border-box;
        overflow-y: scroll;
        background-color: #F4F9FA;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    #top {
        width: 100%;
        display: flex;
        align-items: space-between;
        padding: 16px 0px 5px 0px;
        margin-left: 16px;
        margin-right: 16px;
        justify-items: center;
        justify-content: space-between;
        overflow: hidden;
        font-size: 14px;
        text-align: center;
        #wrap-checkbox {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-right: 32px;
            align-items: center;
            p {
                color: #5F5F5F;
                font-weight: bold;
                text-align: center;
                cursor: default;
            }
        }
    }
    
    #list {
        flex: 1;
        box-sizing: border-box;
        overflow-y: scroll;
    }

    #no-data {
        height: 100%;
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #ABB3BA ;
    }
    
    #load-more {
        width: 100%;
        .load-more {
            margin: 10px auto;
            border-bottom: 3px solid #888;
            border-top: 3px solid #a6d837;
            border-top: 3px solid #a6d837;
            border-left: 3px solid #a6d837;
            border-right: 3px solid #888;
            border-right: 3px solid #a6d837;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            animation: spin 2s linear infinite;
        }
    
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            40% {
                transform: rotate(288deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 26px;
      }
      .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
      .slider:before {
        position: absolute;
        content: "";
        height: 25px;
        width: 25px;
         left: 2px;
         right: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }

    input:checked + .slider {
        background-color: #34C759;
    }
    
    input:focus + .slider {
        box-shadow: 0 0 1px #34C759;
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(21px);
        -ms-transform: translateX(21px);
        transform: translateX(21px);
    }

    .slider.round {
        border-radius: 31px;
    }
    .slider.round:before {
        border-radius: 50%;
    }
}
