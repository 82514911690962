.policy {
    width: 95%;
    height: 635px;
    font-family: sans-serif;
    color: black;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 1.4;
    margin: 0 auto;
    padding: 10px 10px 10px 10px;
    overflow: scroll;
}
.decimal {
    list-style-type: decimal;
}
.custom_list {
    list-style: none;
    padding-inline-start: 0;
}
.article-indent {
    text-indent: 0px;
}
ul {
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 15px;
}
