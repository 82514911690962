#character-container {
    width: 375px;
    height: 667px;
    overflow: scroll;
    span{
        padding: 10px;
    }
    button {
        border-width: 0px;
    }
}
