#images {
    width: 375px;
    height: 675px;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    background-color: #f4f9fa;
    margin: 0px auto;
    position: relative;
    display: flex;
    flex-direction: column;
    header {
        width: 100%;
        height: 40pt;
        background-color: #fff;
        border-bottom: 1px solid #d8d8d8;
        div {
            width: 30%;
            float: left;
            line-height: 50px;
            height: 50px;
            font-size: 12px;
            font-weight: 300;
            margin: 0px;
            padding: 0px;
        }
        div.back {
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                font-size: 15px;
                color: #000000;
            }
            span:hover {
                cursor: pointer;
            }
        }
        div.right {
            text-align: right;
            padding-top: 5pt;
        }
        h3 {
            width: 40%;
            float: left;
            line-height: 50px;
            height: 50px;
            font-size: 12px;
            font-weight: 300;
            margin: 0px;
            padding: 0px;
            text-align: center;
            font-size: 18px;
            color: #000000;
        }
        h3:hover {
            cursor: default;
        }
    }
    div.mainContainer {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div.imagesContainer {
            margin-top: 10px;
            width: 100%;
            height: 520px;
            display: flex;
            flex-direction: column;
            overflow: scroll;
            div {
                div.rowContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    div.item {
                        width: 112px;
                        height: 112px;
                        display: flex;
                        margin: 6px;
                        display: flex;
                        align-items: center;
                        img.selectedImage {
                            width: 110px;
                            height: 110px;
                            object-fit: cover;
                            border-radius: 50%;
                            background-color: white;
                            border-width: 1px;
                            border-color: #a6d837;
                            border-style: solid;
                        }
                        img.unselectedImage {
                            width: 110px;
                            height: 110px;
                            object-fit: cover;
                            border-radius: 50%;
                            background-color: white;
                        }
                    }
                }
            }
        }
        div.buttonContainer {
            margin-top: 30px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            div.button {
                width: 55%;
                border-radius: 25px;
                height: 50px;
                background-color: #ffe033;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 2px #dbbd16;
                span {
                    font-size: 12px;
                    font-weight: 600;
                }
                span:hover {
                    cursor: pointer;
                }
            }
            div.buttonDisable {
                width: 55%;
                border-radius: 25px;
                height: 50px;
                background-color: #ffe033;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 2px #dbbd16;
                opacity: 50%;
                span {
                    font-size: 12px;
                    font-weight: 600;
                }
                span:hover {
                    cursor: pointer;
                }
            }
            div:hover {
                cursor: pointer;
            }
        }
    }
}
