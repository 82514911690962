#img-profile-register {
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #dfe9eb;
    height: 100px;
    width: 100px;
    margin-top: 20px;
    margin-bottom: 10px;
    object-fit: cover;
    border-color: #DFE9EB;
    border-width: 1px;
    border-style: solid;
}

.back-container{
    border-bottom: 1px solid #d8d8d8;
    width: 100%;
    background-color: white;
    height: 50px;
}

.my-back{
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    position: absolute;
    z-index: 2;
}

span.imageError{
    font-size: 12px;
    color: red;
    margin-left: 8px;
    margin-bottom: 10px;
}

.button-choice {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 30px;
    .button-choice-take-picture {
        border: 1px solid #dfe9eb;
        width: 70%;
        height: 30px;
        border-radius: 60px;
        font-weight: normal;
        max-height: 35px;
        background-color: #ffffff;
        text-align: center;
        padding: 6px 0;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

.button-back-setting-info {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 40px;
    padding-bottom: 73px;
    .button-back-setting-info-avatar {
        border: 1px solid #dfe9eb;
        width: 70%;
        border-radius: 60px;
        font-weight: bold;
        max-height: 40px;
        background-color: #ffe033;
        text-align: center;
        padding: 12px 0;
        font-size: 18px;
        font-weight: bold;
        border-right-width: 1px;
        border-right-color: #dbbd16;
        border-left-width: 1px;
        border-left-color: #dbbd16;
        border-bottom-width: 3px;
        border-bottom-color: #dbbd16;
        cursor: pointer;
    }
}

div.buttonContainer {
    margin-top: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    div.button {
        width: 70%;
        border-radius: 20px;
        height: 35px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-size: 14px;
            font-weight: 300;
            color: #36383b;
        }
        span:hover {
            cursor: pointer;
        }
    }
    div.button:hover {
        cursor: pointer;
    }
    label.uploadLabel {
        width: 70%;
        border-radius: 20px;
        border: 1px solid #dfe9eb;
        height: 40px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 300;
        color: #36383b;
    }
    label:hover {
        cursor: pointer;
    }
    #upload-photo {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }
    div.buttonConfirm {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 65%;
        border-radius: 25px;
        height: 50px;
        background-color: #ffe033;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px #dbbd16;
        span {
            font-size: 18px;
            font-weight: 600;
            color: #36383b;
        }
        span:hover {
            cursor: pointer;
        }
    }
    div.buttonConfirm:hover {
        cursor: pointer;
    }
}
