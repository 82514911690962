#loader {
    margin-top: -55px;
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 667px;
    z-index: 1000;
    background: #00000082;
    .loader-topic {
        margin: 374px auto;
        border-bottom: 3px solid #888;
        border-top: 3px solid #a6d837;
        border-top: 3px solid #a6d837;
        border-left: 3px solid #a6d837;
        border-right: 3px solid #888;
        border-right: 3px solid #a6d837;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        40% {
            transform: rotate(288deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

#rootContainerCreate {
    position: fixed;
    z-index: 2;
    width: 372px;
    height: 670px;
    background-color: #00000082;
    div.detailContainerError {
        overflow: scroll;
        width: 85%;
        border-radius: 10px;
        height: 30%;
        top: 50%;
        left: 50%;
        padding: 12px;
        transform: translate(-50%, -50%);
        background: #ffffff;
        position: absolute;
        img.close {
            position: absolute;
            display: flex;
            right: 10px;
            padding: 3px;
            color: #000000;
            font-size: 25px;
            z-index: 100;
        }
        img.close:hover {
            cursor: pointer;
        }
        h3 {
            text-align: center;
            font-size: 16px;
            color: #5f5f5f;
            margin-top: 15%;
            font-weight: bold;
            cursor: default;
        }
        .submit {
            margin-top: 20px;
            margin-right: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-align: center;

            .button-submit {
                border: 1px solid #dfe9eb;
                color: #ffffff;
                width: 50%;
                height: 25px;
                margin-left: 0px;
                align-items: center;
                background-color: #a6d837;
                font-size: 18px;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }
    div.detailContainerHave {
        overflow: scroll;
        width: 85%;
        border-radius: 10px;
        height: 35%;
        top: 50%;
        left: 50%;
        padding: 12px;
        transform: translate(-50%, -50%);
        background: #ffffff;
        position: absolute;
        img.close {
            position: absolute;
            display: flex;
            right: 10px;
            padding: 3px;
            color: #000000;
            font-size: 25px;
            z-index: 100;
        }
        img.close:hover {
            cursor: pointer;
        }
        h3 {
            text-align: center;
            font-size: 16px;
            color: #5f5f5f;
            margin-top: 15%;
            font-weight: bold;
        }
        .submit {
            margin-top: 20px;
            margin-right: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-align: center;

            .button-submit {
                border: 1px solid #dfe9eb;
                color: #ffffff;
                width: 50%;
                height: 25px;
                margin-left: 0px;
                align-items: center;
                background-color: #a6d837;
                font-size: 18px;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }
    div.detailContainerCreate {
        overflow: scroll;
        width: 85%;
        border-radius: 10px;
        height: 92%;
        top: 50%;
        left: 50%;
        padding: 12px;
        transform: translate(-50%, -50%);
        background: #99FFCC;
        position: absolute;
        img.close {
            position: absolute;
            display: flex;
            right: 10px;
            padding: 3px;
            color: #000000;
            font-size: 25px;
            z-index: 100;
        }
        img.close:hover {
            cursor: pointer;
        }
        h3 {
            width: 85%;
            text-align: center;
            font-size: 20px;
            color: #000000;
            margin-top: 15px;
            margin-left: 25px;
            margin-right: 25px;
            font-weight: normal;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: default;
        }

        .topic-title {
            margin-top: 2px;
            p {
                margin-top: 2px;
                font-size: 14px;
                font-weight: bold;
                color: #5f5f5f;
                cursor: default;
            }
            div.userNameCreateTopic {
                width: 100%;
                background-color: white;
                border-radius: 4px;
                height: 39px;
                border: 1px solid #dfe9eb;
                display: flex;
                align-items: center;
                position: relative;
                flex-direction: row;
                cursor: pointer;
                span {
                    width: 82%;
                    padding: 9px 10px 9px 10px;
                    font-weight: bold;
                    font-size: 16px;
                    color: #5f5f5f;
                }
                img {
                    position: absolute;
                    right: 0px;
                    padding: 15px 10px 15px 10px;
                }
            }

            // input.title {
            //     font-weight: bold;
            //     font-size: 12px;
            //     width: 94%;
            //     color: #36383b;
            //     border-radius: 4px;
            //     border: 1px solid #dfe9eb;
            //     padding: 10px;
            //     font-family: 'Hiragino Kaku Gothic ProN';
            //     outline: none;
            // }

            div.textarea {
                width: 100%;
                border: 1px solid black;
                border-radius: 6px;
                padding: -10px;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;

                textarea.title {
                    width: 93%;
                    font-weight: bold;
                    font-size: 12px;
                    height: 100px;
                    color: #36383b;
                    padding: 10px 9px 10px 9px;
                    font-family: 'Hiragino Kaku Gothic ProN';
                    outline: none;
                    resize: none;
                    overflow-y: auto;
                    border-width: 0px;
                    margin-bottom: 10px;
                }

                textarea.comment {
                    width: 93%;
                    font-weight: bold;
                    font-size: 12px;
                    height: 100px;
                    color: #36383b;
                    padding: 10px 9px 10px 9px;
                    font-family: 'Hiragino Kaku Gothic ProN';
                    resize: none;
                    outline: none;
                    overflow-y: auto;
                    border-width: 0px;
                }

                textarea.detail {
                    width: 93%;
                    font-weight: bold;
                    font-size: 12px;
                    height: 70px;
                    max-height: 70px;
                    color: #36383b;
                    padding: 10px 9px 10px 9px;
                    font-family: 'Hiragino Kaku Gothic ProN';
                    resize: none;
                    outline: none;
                    overflow-y: auto;
                    border-width: 0px;
                }
            }

            // input.info:focus {
            //     outline: none;
            // }

            span.msg {
                color: red;
                display: block;
                font-size: 12px;
                margin-left: 5px;
                margin-bottom: 5px;
                cursor: default;
            }

            .wrap-time {
                margin-top: 10px;
                width: 100%;
                display: flex;
                flex-direction: row;
                position: relative;
                align-items: center;
                min-height: 30px;
                .horizontalTime {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    height: 40px;
                    span.timeLabel {
                        border: 1px solid black;
                        font-size: 14px;
                        width: 60%;
                        font-weight: bold;
                        text-align: center;
                        padding: 10px 0;
                        color: #5f5f5f;
                        border-radius: 4px;
                        background-color: white;
                        cursor: default;
                    }
                    div.topicExpireTime {
                        margin-left: 5%;
                        width: 45%;
                        background-color: white;
                        border-radius: 4px;
                        height: 39px;
                        border: 1px solid #000000;
                        display: flex;
                        align-items: center;
                        position: relative;
                        flex-direction: row;
                        cursor: pointer;
                        span {
                            width: 82%;
                            padding: 9px 10px 9px 10px;
                            font-weight: bold;
                            font-size: 14px;
                            color: #5f5f5f;
                        }
                        img {
                            position: absolute;
                            right: 0px;
                            padding: 15px 10px 15px 10px;
                        }
                    }
                }
            }

            .wrap-call-label {
                margin-top: 10px;
                width: 100%;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                display: flex;

                .area {
                    background-color: white;
                    padding: 8px;
                    border: solid 1px #000000;
                    border-radius: 3px;
                }

                .label-switch-time {
                    font-size: 14px;
                    font-weight: bold;
                    color: #5f5f5f;
                    cursor: default;
                }

                .switch {
                    position: relative;
                    display: inline-block;
                    width: 50px;
                    height: 26px;
                }
                .switch input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                }
                .slider:before {
                    position: absolute;
                    content: '';
                    height: 25px;
                    width: 25px;
                    left: 2px;
                    right: 2px;
                    background-color: white;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                }

                input:checked + .slider {
                    background-color: #34c759;
                }

                input:focus + .slider {
                    box-shadow: 0 0 1px #34c759;
                }
                input:checked + .slider:before {
                    -webkit-transform: translateX(21px);
                    -ms-transform: translateX(21px);
                    transform: translateX(21px);
                }

                .slider.round {
                    border-radius: 15px;
                }
                .slider.round:before {
                    border-radius: 50%;
                }
            }
            .label-price-chat {
                margin-top: 10px;
                width: 100%;
                display: flex;
                flex-direction: row;
                position: relative;
                align-items: center;
                min-height: 30px;

                .label-price {
                    font-size: 14px;
                    font-weight: bold;
                    color: #5f5f5f;
                }
            }

            .label-price-call {
                margin-top: 10px;
                width: 100%;
                display: flex;
                flex-direction: row;
                position: relative;
                align-items: center;
                min-height: 30px;

                .label-price {
                    font-size: 14px;
                    font-weight: bold;
                    color: #5f5f5f;
                }
            }

            div.priceSettings {
                padding-top: 15px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                div.priceContainer {
                    height: 60px;
                    div.priceValue {
                        height: 20px;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        span.note {
                            font-size: 14px;
                            font-weight: 600;
                            color: #5f5f5f;
                            margin-right: 5px;
                        }
                        span.value {
                            font-size: 14px;
                            font-weight: 600;
                            color: #5f5f5f;
                            background-color: white;
                            border: 1px #dfe9eb;
                            border-radius: 2px;
                            border-style: solid;
                            margin-right: 5px;
                        }
                        span:hover {
                            cursor: default;
                        }

                        img.checkbox-checked {
                            width: 20px;
                            height: 20px;
                            color: #a6d837;
                            cursor: pointer;
                            margin-right: 5px;
                        }

                        img.checkbox-unchecked {
                            width: 20px;
                            height: 20px;
                            color: #a6d837;
                            cursor: pointer;
                            margin-right: 5px;
                        }
                    }
                    div.settingPrice {
                        margin-top: 10px;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        span.minPrice {
                            width: 20%;
                            font-size: 14px;
                            font-weight: 600;
                            color: #5f5f5f;
                            text-align: end;
                            padding-right: 1px;
                            border-width: 0px 2px 0px 0px;
                            border-color: #707070;
                            border-style: solid;
                        }
                        input.priceSlider {
                            -webkit-appearance: none;
                            width: 50%;
                            height: 5px;
                            background: #707070;
                            outline: none;
                            opacity: 0.7;
                            -webkit-transition: 0.2s;
                            transition: opacity 0.2s;
                        }

                        input.priceSlider:hover {
                            opacity: 1;
                        }

                        input.priceSlider::-webkit-slider-thumb {
                            -webkit-appearance: none;
                            appearance: none;
                            width: 20px;
                            height: 20px;
                            background: white;
                            cursor: pointer;
                            border: 1px #707070;
                            border-style: solid;
                            border-radius: 50%;
                        }
                        span.maxPrice {
                            width: 34%;
                            font-size: 14px;
                            font-weight: 600;
                            color: #5f5f5f;
                            text-align: start;
                            padding-left: 2px;
                            border-width: 0px 0px 0px 2px;
                            border-color: #707070;
                            border-style: solid;
                        }
                    }
                }
            }

            .submit {
                margin-top: 15px;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
                min-height: 60px;
                .button-submit {
                    border: 1px solid #dfe9eb;
                    width: 80%;
                    border-radius: 60px;
                    font-weight: bold;
                    max-height: 40px;
                    background-color: #ffe033;
                    text-align: center;
                    padding: 15px 0;
                    margin-left: 30px;
                    font-size: 18px;
                    font-weight: bold;
                    border-right-width: 1px;
                    border-right-color: #dbbd16;
                    border-left-width: 1px;
                    border-left-color: #dbbd16;
                    border-bottom-width: 4px;
                    border-bottom-color: #dbbd16;
                    cursor: pointer;
                }
            }
        }
    }
}
