.audio-play-button {
    width: 30%;
    padding: 5px;
    font-weight: bold;
    background-color: #efcd82;
    justify-content: center;
    align-items: center;
    border: solid 1px #dcbe7b;
    border-radius: 10px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    left: 15px;
    top: 350px;
}
._1ceqH{
    padding: 10px;
    margin-bottom: 50px;
    
    ._2N9dq {
        font-weight: normal;
        font-size: 14px;
        text-align: left;
        padding: 20px;
    }
    ._1Yplu{
        display: flex;
        padding-left: 20px;
        padding-right: 20px;
        justify-content: space-between;
        margin-top: 125px;
        ._2gd2_ {
            width: 30%;
            padding: 5px;
            font-weight: bold;
            background-color: #efcd82;
            justify-content: center;
            align-items: center;
            border: solid 1px #dcbe7b;
            border-radius: 10px;
            height: 40px;
            cursor: pointer;
            position: absolute;
            left: 230px;
            top: 350px;
            padding-left: 18px;
            padding-right: 18px;
        }
        ._37kfa {
            width: 200px;
            padding: 5px;
            font-weight: 500;
            background-color: #ffd035;
            justify-content: center;
            align-items: center;
            border: solid 3px #dcbe7b;
            border-radius: 10px;
            height: 100px;
            position: absolute;
            top: 450px;
            left: 75px;
            cursor: pointer;
            font-size: 30px;
        }
    }
    ._1YOWG{
        margin-top: 10px;
        ._f2DT8{
            margin-top: 10px;
            span {
                font-size: 18px;
            }
        }
        ._eV_dK{
            font-size: 14px;
            text-align: center;
            margin-top: 35px;
            padding: 10px;
        }
    }
    ._3wi1g{
        width: 50px;
        margin-top: 10px;
    }
    .stop_icon{
        background-color: red;
        width: 50px;
        height: 100px;
    }
    ._1bSom{
        width: 120px;
        padding: 5px;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: solid 2px #ff0000;
        height: 70px;
        cursor: pointer;
        text-decoration: none;
        color: white;
        background-color: #c53c00;
        position: absolute;
        left: 100px;
        top: 450px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
    }
    ._3nQu5{
        display: none;
        width: 40%;
        padding: 5px;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        height: 50px;
        cursor: pointer;
        text-decoration: none;
        color: blue;
        padding: 20px;
    }
    ._qxztz{
        margin-top: 0px;
    }
}
._1dpop{
    background-color: #efcd82;
    padding: 16px;
    position: absolute;
    top: 270px;
    left: 50px;
    border: solid 2px #dcbe7b;
    border-radius: 15px;
    display: flex;
    cursor: pointer;
}
._1dpop1{
    background-color: #ffffff;
    padding: 16px;
    position: absolute;
    top: 250px;
    left: 50px;
    // border: solid 2px #dcbe7b;
    border-radius: 15px;
    display: flex;
    cursor: pointer;
}
._1dpop2{
    background-color: #ffffff;
    padding: 16px;
    position: absolute;
    top: 195px;
    left: 30px;
    // border: solid 2px #dcbe7b;
    border-radius: 15px;
    display: flex;
    cursor: pointer;
}
._3neb0{
    width: 70px;
}
._3neb01{
    width: 70px;
    position:absolute;
    left: 140px;
    top: 400px;
}
._f2DT8{
    width: 40%;
    position: absolute;
    top: 272px;
    left: 170px;
    font-size: 18px;
}

._1ceqH ._1YOWG ._f2DT8 span{
    font-size: 25px;
}

.detail-user{
    height: 520px;
}
.alter-div{
    width: 80px;
    padding: 5px;
    font-weight: bold;
    background-color: #efcd82;
    justify-content: center;
    align-items: center;
    border: solid 1px #dcbe7b;
    border-radius: 10px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    left: 148px;
    top: 350px;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.max-time{
    font-size: 14px;
    position: absolute;
    top: 260px;
    right: 70px;
    font-weight: normal;
}
._1lB9c{
    padding-left: 11px;
}
.hint-text {
    width: 265px;
    position: absolute;
    top: 415px;
    left: 47px;
    font-size: 14px;

}
.name{
    font-size: 28px !important;
}
.detail{
    font-size: 14px !important;
    font-weight: bold;
}
.alter-record{
    background-color: #ffffff;
    padding: 16px;
    position: absolute;
    top: 260px;
    left: 140px;
    // border: solid 2px #dcbe7b;
    border-radius: 15px;
    width: 51px;
    height: 66px;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
.record-text{
    position: absolute;
    top: 315px;
    left: 95px;
}
.record-text1{
    position: absolute;
    top: 315px;
    left: 95px;
}