.filter-theme-step1-wrap {
    vertical-align: middle;
    width: 100%;
    padding-top: 0px;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0px;
}
.filter-theme-step2-wrap {
    vertical-align: middle;
    width: 100%;
    padding-top: 0px;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 113px;
}

.filter-theme-step3-wrap {
    vertical-align: middle;
    width: 100%;
    padding-top: 0px;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 237px;
}

.back-no-click {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#e3f57f, #a9e677);
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
}


// .back-no-click {
//     width: 100%;
//     height: 100%;
//     background-color: transparent;
//     z-index: 100;
//     position: absolute;
//     top: 0;
//     left: 0;
// }

.filter-theme-step1 {
    margin: auto;
    width: 80% !important;
    text-align: center;
    height: 360px;
    background-color: #ffffff;
    border: 2px solid #a6d837;
    border-radius: 10px;
}

.filter-theme-text {
    text-align: center;
    color: #36383b;
    font-weight: bold;
    font-size: 17px;
    background-color: white;
    padding: 5px 5px;
}

.filter-theme-text:hover {
    cursor: default;
}

.filter-theme-list-option {
    margin: auto;
    margin-top: 30px !important;
    width: 80% !important;
    height: 320px;
    overflow-y: scroll;
    padding: 15px;
    background-color: #ffffff;
    border: 2px solid #a6d837;
    border-radius: 10px;
}
// .filter-theme-list-option::-webkit-scrollbar-track
// {
// 	-webkit-box-shadow: inset 0 0 6px #fff;
// 	border-radius: 10px;
// 	background-color: #fff;
// }
// .filter-theme-list-option::-webkit-scrollbar {
//     width: 5px;
//     display: block;
// }

// .filter-theme-list-option::-webkit-scrollbar-thumb {
//     background: #fff;
//     border-radius: 10px;
// }
.filter-theme-option {
    position: relative;
    padding-top: 12px;
    width: 100%;
    font-size: 16px;
}

.filter-theme-submit-button-wrap {
    margin-top: 15px !important;
    text-align: center;
}

.filter-theme-submit-button {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    margin: auto;
    width: 230px;
    height: 50px;
    background-color: #ffe033;
    border: #ffe86a;
    border-radius: 30px;
    border-color: #ffe033 #dbbd16 #dbbd16;
    border-style: solid;
    border-width: 1px 0px 3px;
}

.filter-active {
    background-color: #ff9d00;
}

.filter-inactive {
    opacity: 50%;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 15px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    width: 90%;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddeff2;
    border-radius: 100%;
    background: #f4f9fa;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #ff9d00;
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.timeContainer {
    div.topicTime {
        position: absolute;
        right: 35px;
        top: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        img.topicClock {
            width: 12px;
            height: 12px;
        }
        span.topicExistTime {
            font-size: 12px;
            color: #b5bec6;
            margin-left: 4px;
            margin-right: 4px;
            cursor: default;
        }
    }
}
