.confirm-container {
    position: fixed;
    width: 372px;
    height: 667px;

    .main-title {
        width: 100%;
        height: 645px;
        // position: absolute;
        div.wrap-main-title {
            height: 120px;
            padding-top: 30px;
            justify-content: center;
            align-content: center;
            align-self: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            background: #a6d837;
            h3 {
                font-size: 18px;
                font-weight: bold;
                margin-top: 30px;
                color: white;
                align-items: center;
            }
            h5 {
                font-size: 11px;
                margin-top: 5px;
                color: white;
                font-weight: normal;
                align-items: center;
            }
        }
        div.fill-title-confirm {
            overflow: scroll;
            padding-top: 30px;
            flex-direction: column;
            background-color: #f4f9fa;
            display: flex;
            height: 75%;
            align-content: center;

            div.label-user-name {
                position: relative;
                display: flex;
                .label-title-right {
                    position: absolute;
                    right: 20px;
                    margin-top: 12px;
                    font-weight: bold;
                    font-size: 12px;
                    color: #abb3ba;
                    cursor: pointer;
                }
            }
            .img-profile-confirm {
                border-radius: 50%;
                align-content: center;
                justify-content: center;
                align-items: center;
                height: 100px !important;
                width: 100px !important;
                align-self: center;
                border-color: #dfe9eb;
                border-width: 1px;
                border-style: solid;
            }
            .label-change-image {
                margin-top: 12px;
                align-content: center;
                justify-content: center;
                font-weight: bold;
                font-size: 12px;
                color: #abb3ba;
                align-self: center;
                margin-bottom: 12px;
                cursor: pointer;
            }
            .hr-style {
                align-content: center;
                margin-top: 10px;
                justify-content: center;
                margin-left: 20px;
                margin-right: 20px;
                background-color: #bbb;
            }
        }

        div.fill-label-confirm {
            display: flex-start;
            flex-direction: column;
            .label-title {
                margin-top: 12px;
                margin-left: 20px;
                font-size: 14px;
                font-weight: 600;
                color: #5f5f5f;
                cursor: default;
            }
            .text-input-data {
                align-content: center;
                align-items: center;
                justify-content: center;
                padding-left: 20px;
                margin-top: 5px;
                padding-top: 12px;
                padding-bottom: 12px;
                background-color: #ffffff;
                cursor: default;
            }
        }
        .confirm-submit {
            margin-top: 30px;
            margin-bottom: 30px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
            min-height: 60px;
            .button-confirm-submit {
                border: 1px solid #dfe9eb;
                width: 70%;
                border-radius: 60px;
                font-weight: bold;
                max-height: 40px;
                background-color: #ffe033;
                text-align: center;
                padding: 15px 0;
                font-size: 18px;
                font-weight: bold;
                border-right-width: 1px;
                border-right-color: #dbbd16;
                border-left-width: 1px;
                border-left-color: #dbbd16;
                border-bottom-width: 4px;
                border-bottom-color: #dbbd16;
                cursor: pointer;
            }
        }
    }
}
