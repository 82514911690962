#verify {
    width: 375px;
    height: 675px;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    background-color: #ffffff;
    margin: 0px auto;
    position: relative;
    header {
        width: 100%;
        height: 50px;
        background: #fff;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        div {
            width: 30%;
            float: left;
            line-height: 50px;
            height: 50px;
            font-size: 12px;
            font-weight: 300;
            margin: 0px;
            padding: 0px;
        }
        div.back {
            display: flex;
            align-items: center;
            justify-items: center;
            img {
                padding: 10px;
            }
        }
        div.back:hover {
            cursor: pointer;
        }
        div.none {
            text-align: right;
            padding-top: 5pt;
            img {
                margin: 5px 10px 0 0;
                font-size: 3em;
                color: #a6d837;
                height: 25px;
            }
        }
        h3 {
            width: 40%;
            float: left;
            line-height: 50px;
            height: 50px;
            font-size: 12px;
            font-weight: 300;
            margin: 0px;
            padding: 0px;
            text-align: center;
            font-size: 18px;
        }
    }
    div.mainContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span.note {
            margin-top: 60px;
            color: #000000;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
        }
        div.inputContainer {
            margin-top: 120px;
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            span.title {
                color: #5f5f5f;
                font-size: 14px;
                font-weight: 600;
                margin-left: 20px;
                margin-bottom: 5px;
            }
            input {
                width: 80%;
                padding: 16px;
                border-width: 1px;
                background: #f4f9fa;
                border-color: #dfe9eb;
                border-radius: 6px;
                border-style: solid;
                font-size: 12px;
                font-weight: 600;
                margin-left: 20px;
                margin-right: 20px;
            }
            input:focus {
                outline: none;
            }

            span:hover {
                cursor: default;
            }
            span.error {
                margin-top: 5px;
                margin-left: 20px;
                color: red;
                font-size: 12px;
            }
        }
        span:hover {
            cursor: default;
        }
        div.buttonContainer {
            margin-top: 120px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            div.button {
                width: 65%;
                border-radius: 25px;
                height: 50px;
                background-color: #ffe033;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 2px #dbbd16;
                span {
                    font-size: 18px;
                    font-weight: 600;
                    color: #36383b;
                }
                span:hover {
                    cursor: pointer;
                }
            }
            div.button:hover {
                cursor: pointer;
            }
        }
    }
}
