body {
    margin: 0px;
    padding: 0px;
    background: #fafafa;
}

body {
    margin: 0px;
    padding: 0px;
    background: #fafafa;
    font-family: 'Hiragino Kaku Gothic ProN';
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 5px;
}
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #999;
}

#friend-load-more {
    width: 100%;
    .friend-load-more {
        margin: 10px auto;
        border-bottom: 3px solid #888;
        border-top: 3px solid #a6d837;
        border-top: 3px solid #a6d837;
        border-left: 3px solid #a6d837;
        border-right: 3px solid #888;
        border-right: 3px solid #a6d837;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        40% {
            transform: rotate(288deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

#friendlist {
    width: 375px;
    height: 587px;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    background-color: white;
    margin: 0px auto;
    position: relative;
    header {
        width: 100%;
        height: 40pt;
        background: #fff;
        border-bottom: 1px solid #d8d8d8;
        div,
        h3 {
            width: 33.3333%;
            float: left;
            line-height: 50px;
            height: 40px;
            font-weight: 300;
            margin: 0px;
            padding: 0px;
            color: #36383b;
            font-size: 18px;
            font-weight: bold;
        }
        h3:hover {
            cursor: default;
        }
        div.back {
            i {
                margin: 5px 0 0 10px;
                font-size: 4em;
            }
        }
        div.addfriend {
            text-align: right;
            padding-top: 2px;
            img {
                font-size: 3em;
                color: #a6d837;
                height: 25px;
                padding: 10px;
                cursor: pointer;
            }
        }
        h3 {
            text-align: center;
            font-size: 18px;
        }
    }
    div.mainContainer {
        position: relative;
        background: #000;
        display: flex;
        flex-direction: column;
        #friend-content {
            width: 100%;
            height: 547px;
            box-sizing: border-box;
            overflow-y: scroll;
            background-color: #f4f9fa;
            position: relative;
            div.exception {
                width: 100%;
                height: 100%;
                justify-content: center;
                span {
                    font-size: 20px;
                    color: #36383b;
                }
            }
            div.item-odd {
               height: 43px;
                margin: 5px 10px 10px 10px;
                background-color: #fdffce;
                padding-right: 10px;
                padding-left: 5px;
                border-radius: 0px;
                display: flex;
                overflow: hidden;
                font-weight: bold;
                box-shadow: 3px 3px #a6d837;
                img.avatar {
                    width: 50px;
                    float: left;
                    height: 50px;
                    margin-right: 15px;
                    border-radius: 50%;
                    background-color: white;
                    object-fit: cover;
                    border-width: 1px;
                    border-color: #f0f0f0;
                    border-style: solid;
                }
                img:hover {
                    cursor: pointer;
                }
                div.friend_chat_content {
                    position: relative;
                    width: 340px;
                    flex-direction: column;
                    display: flex;
                    height: 55px;
                    flex-direction: row;
                    align-items: center;
                    h3 {
                        width: 100%;
                        font-size: 14px;
                        padding: 0px;
                        color: #36383b;
                        margin-bottom: 5px;
                    }
                    div.unreadBadge {
                        position: absolute;
                        right: 5px;
                        top: 1px;
                        width: 19px;
                        height: 19px;
                        border-radius: 50%;
                        display: flex;
                        background-color: #fc0000;
                        justify-content: center;
                        align-items: center;
                        span {
                            font-size: 10px;
                            color: #f4f9fa;
                        }
                    }
                    p.read_message {
                        width: 83%;
                        min-width: 83%;
                        padding: 0px;
                        margin-top: 4px;
                        color: #aaafb7;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-lines: 1;
                        font-size: 12px;
                    }
                    p.unread_message {
                        width: 83%;
                        min-width: 83%;
                        padding: 0px;
                        color: #36383b;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-lines: 1;
                        font-size: 12px;
                    }
                }
                i.callable {
                    position: absolute;
                    right: 10px;
                    top: 18px;
                    padding: 10px;
                    color: #a6d837;
                    font-size: 25px;
                }
                i.uncallable {
                    position: absolute;
                    right: 10px;
                    top: 18px;
                    padding: 10px;
                    color: #abb3ba;
                    font-size: 25px;
                }
                div.functionContainer {
                    display: inline-flex;
                    position: absolute;
                    top: 0px;
                    right: -4px;
                    flex-direction: row;
                    width: 80px;
                    height: 22px;
                    div.buttonContainer {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        img.blockIcon {
                            position: absolute;
                            width: 18px;
                            height: 18px;
                            top: 2px;
                        }
                        img.deleteIcon {
                            position: absolute;
                            top: 2px;
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
             div.item-even {
               height: 43px;
                margin: 15px 10px 10px 10px;
                background-color: #fff;
                padding-right: 10px;
                padding-left: 5px;
                border-radius: 0px;
                display: flex;
                overflow: hidden;
                font-weight: bold;
                box-shadow: 3px 3px #a6d837;
                flex-direction: row;
                align-items: center;
                img.avatar {
                    width: 30px;
                    float: left;
                    height: 30px;
                    margin-right: 15px;
                    border-radius: 50%;
                    background-color: white;
                    object-fit: cover;
                    border-width: 1px;
                    border-color: #f0f0f0;
                    border-style: solid;
                }
                img:hover {
                    cursor: pointer;
                }
                div.friend_chat_content {
                    position: relative;
                    width: 340px;
                    flex-direction: column;
                    display: flex;
                    height: 55px;
                    align-items: center;
                    h3 {
                        width: 60%;
                        font-size: 14px;
                        padding: 0px;
                        color: #36383b;
                        margin-bottom: 5px;
                    }
                    div.unreadBadge {
                        position: absolute;
                        right: 5px;
                        top: 1px;
                        width: 19px;
                        height: 19px;
                        border-radius: 50%;
                        display: flex;
                        background-color: #fc0000;
                        justify-content: center;
                        align-items: center;
                        span {
                            font-size: 10px;
                            color: #f4f9fa;
                        }
                    }
                    p.read_message {
                        width: 83%;
                        min-width: 83%;
                        padding: 0px;
                        margin-top: 4px;
                        color: #aaafb7;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-lines: 1;
                        font-size: 12px;
                    }
                    p.unread_message {
                        width: 83%;
                        min-width: 83%;
                        padding: 0px;
                        color: #36383b;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-lines: 1;
                        font-size: 12px;
                    }
                }
                i.callable {
                    position: absolute;
                    right: 10px;
                    top: 18px;
                    padding: 10px;
                    color: #a6d837;
                    font-size: 25px;
                }
                i.uncallable {
                    position: absolute;
                    right: 10px;
                    top: 18px;
                    padding: 10px;
                    color: #abb3ba;
                    font-size: 25px;
                }
                div.functionContainer {
                    display: inline-flex;
                    position: absolute;
                    top: 0px;
                    right: -4px;
                    flex-direction: row;
                    width: 80px;
                    height: 22px;
                    div.buttonContainer {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        img.blockIcon {
                            position: absolute;
                            width: 18px;
                            height: 18px;
                            top: 2px;
                        }
                        img.deleteIcon {
                            position: absolute;
                            top: 2px;
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }
}

#friendlist-call {
    width: 375px;
    height: 587px;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    background-color: white;
    margin: 0px auto;
    position: relative;
    header {
        width: 100%;
        height: 40pt;
        background: #fff;
        border-bottom: 1px solid #d8d8d8;
        div,
        h3 {
            width: 33.3333%;
            float: left;
            line-height: 50px;
            height: 40px;
            font-size: 12px;
            font-weight: 300;
            margin: 0px;
            padding: 0px;
        }
        h3:hover {
            cursor: default;
        }
        div.back {
            i {
                margin: 5px 0 0 10px;
                font-size: 4em;
            }
        }
        div.addfriend {
            text-align: right;
            padding-top: 2px;
            img {
                font-size: 3em;
                color: #a6d837;
                height: 25px;
                padding: 10px;
                cursor: pointer;
            }
        }
        h3 {
            text-align: center;
            font-size: 18px;
        }
    }
    div.mainContainer {
        position: relative;
        background: #000;
        display: flex;
        flex-direction: column;
        #friend-content {
            background-image: linear-gradient(#e3f57f, #a9e677);
            width: 100%;
            height: 547px;
            box-sizing: border-box;
            overflow-y: scroll;
            background-color: #f4f9fa;
            position: relative;
            div.exception {
                width: 100%;
                height: 100%;
                justify-content: center;
                span {
                    font-size: 20px;
                    color: #36383b;
                }
            }
            div.item-odd {
                height: 43px;
                margin: 0px 10px 10px 10px;
                background-color: #fdffce;
                padding-right: 10px;
                padding-left: 5px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                border-left: solid 1px rgb(182, 181, 181);
                border-bottom: solid 1px rgb(182, 181, 181);
                border-right: solid 3px rgb(182, 181, 181);
                display: flex;
                overflow: hidden;
                font-weight: bold;
                box-shadow: 3px 3px #a6d837;
                img.avatar {
                    width: 50px;
                    float: left;
                    height: 50px;
                    margin-right: 15px;
                    border-radius: 50%;
                    background-color: white;
                    object-fit: cover;
                    border-width: 1px;
                    border-color: #f0f0f0;
                    border-style: solid;
                }
                img:hover {
                    cursor: pointer;
                }
                div.friend_chat_content {
                    position: relative;
                    width: 340px;
                    flex-direction: column;
                    display: flex;
                    height: 55px;
                    flex-direction: row;
                    align-items: center;
                    h3 {
                        width: 100%;
                        font-size: 14px;
                        padding: 0px;
                        color: #36383b;
                        margin-bottom: 5px;
                    }
                    div.unreadBadge {
                        position: absolute;
                        right: 5px;
                        top: 1px;
                        width: 19px;
                        height: 19px;
                        border-radius: 50%;
                        display: flex;
                        background-color: #fc0000;
                        justify-content: center;
                        align-items: center;
                        span {
                            font-size: 10px;
                            color: #f4f9fa;
                        }
                    }
                    p.read_message {
                        width: 83%;
                        min-width: 83%;
                        padding: 0px;
                        margin-top: 4px;
                        color: #aaafb7;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-lines: 1;
                        font-size: 12px;
                    }
                    p.unread_message {
                        width: 83%;
                        min-width: 83%;
                        padding: 0px;
                        color: #36383b;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-lines: 1;
                        font-size: 12px;
                    }
                }
                i.callable {
                    position: absolute;
                    right: 10px;
                    top: 18px;
                    padding: 10px;
                    color: #a6d837;
                    font-size: 25px;
                }
                i.uncallable {
                    position: absolute;
                    right: 10px;
                    top: 18px;
                    padding: 10px;
                    color: #abb3ba;
                    font-size: 25px;
                }
                div.functionContainer {
                    display: inline-flex;
                    position: absolute;
                    top: 0px;
                    right: -4px;
                    flex-direction: row;
                    width: 80px;
                    height: 22px;
                    div.buttonContainer {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        img.blockIcon {
                            position: absolute;
                            width: 18px;
                            height: 18px;
                            top: 2px;
                        }
                        img.deleteIcon {
                            position: absolute;
                            top: 2px;
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
             div.item-even {
               height: 43px;
                margin: 15px 10px 0px 10px;
                background-color: #fdffce;
                padding-right: 10px;
                padding-left: 5px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-left: solid 1px rgb(182, 181, 181);
                border-top: solid 1px rgb(182, 181, 181);
                border-right: solid 3px rgb(182, 181, 181);
                display: flex;
                overflow: hidden;
                font-weight: bold;
                box-shadow: 3px 3px #a6d837;
                flex-direction: row;
                align-items: center;
                border-bottom: dotted 1px #ccd080;
                img.avatar {
                    width: 30px;
                    float: left;
                    height: 30px;
                    margin-right: 15px;
                    border-radius: 50%;
                    background-color: white;
                    object-fit: cover;
                    border-width: 1px;
                    border-color: #f0f0f0;
                    border-style: solid;
                }
                img:hover {
                    cursor: pointer;
                }
                div.friend_chat_content {
                    position: relative;
                    width: 340px;
                    flex-direction: column;
                    display: flex;
                    height: 55px;
                    align-items: center;
                    h3 {
                        width: 60%;
                        font-size: 14px;
                        padding: 0px;
                        color: #36383b;
                        margin-bottom: 5px;
                    }
                    div.unreadBadge {
                        position: absolute;
                        right: 5px;
                        top: 1px;
                        width: 19px;
                        height: 19px;
                        border-radius: 50%;
                        display: flex;
                        background-color: #fc0000;
                        justify-content: center;
                        align-items: center;
                        span {
                            font-size: 10px;
                            color: #f4f9fa;
                        }
                    }
                    p.read_message {
                        width: 83%;
                        min-width: 83%;
                        padding: 0px;
                        margin-top: 4px;
                        color: #aaafb7;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-lines: 1;
                        font-size: 12px;
                    }
                    p.unread_message {
                        width: 83%;
                        min-width: 83%;
                        padding: 0px;
                        color: #36383b;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-lines: 1;
                        font-size: 12px;
                    }
                }
                i.callable {
                    position: absolute;
                    right: 10px;
                    top: 18px;
                    padding: 10px;
                    color: #a6d837;
                    font-size: 25px;
                }
                i.uncallable {
                    position: absolute;
                    right: 10px;
                    top: 18px;
                    padding: 10px;
                    color: #abb3ba;
                    font-size: 25px;
                }
                div.functionContainer {
                    display: inline-flex;
                    position: absolute;
                    top: 0px;
                    right: -4px;
                    flex-direction: row;
                    width: 80px;
                    height: 22px;
                    div.buttonContainer {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        img.blockIcon {
                            position: absolute;
                            width: 18px;
                            height: 18px;
                            top: 2px;
                        }
                        img.deleteIcon {
                            position: absolute;
                            top: 2px;
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }
}

#confirmRemoveDialog {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    span.confirmTitle {
        font-size: 14px;
        font-weight: 600;
        color: #5f5f5f;
        font-family: 'Hiragino Kaku Gothic ProN';
        cursor: default;
    }
    div.confirmContainer {
        width: 100%;
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        div.buttonOk {
            width: 30%;
            height: 30px;
            border-radius: 20px;
            background-color: #ffe033;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 2px #dbbd16;
            span {
                color: #36383b;
                font-size: 12px;
                font-weight: 600;
            }
            span:hover {
                cursor: pointer;
            }
        }
        div.buttonOk:hover {
            cursor: pointer;
        }
        div.buttonCancel {
            width: 30%;
            height: 30px;
            border-radius: 20px;
            background-color: #abb3ba;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 2px #707070;
            span {
                color: #36383b;
                font-size: 12px;
                font-weight: 600;
            }
            span:hover {
                cursor: pointer;
            }
        }
        div.buttonCancel:hover {
            cursor: pointer;
        }
    }
}
