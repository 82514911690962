#rootContainer {
    div.detailContainer {
        display: flex;
        margin-top: 28px;
        padding: 0px 0px 10px 0px !important;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        background: #ffffff;
        width: 100%;
        border-radius: 10px;
        vertical-align: middle;
        justify-content: space-between;
        max-height: 550px;
        min-height: 390px;;
        overflow-y: scroll;
        .detail-container-row-1 {
            flex-direction: row;
            display: flex;
            width: 100%;
            align-items: flex-end;
            text-align: left;
            .name {
                width: 40%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                padding-left: 10px;
            }
        }
        .category-green {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            color: #19d419;
            font-size: 12px;
            padding-bottom: 5px;
            font-weight: bold;
        }
        .topic-rating{
            width: '100%';
            display: flex;
            justify-content: flex-start;
            align-items: baseline;
            .topic-status-2 {
                width: 13px;
                height: 13px;
                padding-left: 5px;
                padding-right: 5px;
            }
            .topic-rating {
                font-size: 12px;
                padding-left: 5;
                padding-right: 5;
            }
        }
        button.report {
            background-color: #F6C98B;
            border-left-width: 4px;
            border-color: #F8D792;
            border-radius: 6px;
            font-size: 11px;
            padding: 2px;
            // border-bottom-width: 4px;
            // border-right-width: 4px;
            cursor: pointer;
            width: 90%;
        }
        button.report-disable {
            background-color: #F6C98B;
            border-left-width: 4px;
            border-color: #F6C98B;
            border-radius: 6px;
            font-size: 11px;
            padding: 2px;
            border-bottom-width: 4px;
            border-right-width: 4px;
            cursor: pointer;
            opacity: 0.6;
        }
        div.characterWord {
            width: 100%;
            text-align: left;
            word-break: keep-all;
            color: #10bf10;
            font-size: 13px;
            font-weight: bold;
        }
        div.description-text{
            text-align: left;
            padding: 10px;
            font-size: 12px;
        }
        div.remain-time-container {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: flex-start;
            flex-wrap: wrap;
            text-align: left;
            width: 100%;
            .title{
                font-size: 12px;
                padding: 2px 10px;
                background-color: grey;
                border-radius: 15px;
                color: white;
                margin-right: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .content{
                color: #19d419;
                font-size: 12px;
                padding: 3px 0px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .time-count-down{
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    margin-right: 10px;
                }
            }
        }
        div.oneWordComment {
            width: 92%;
            // background-color: #e9e9e9;
            // min-height: 50px;
            // max-height: 50px;
            min-height: 17px;
            overflow-y: scroll;
            margin-left: 1%;
            border-color: #f1f1f1;
            padding: 5px;
            text-align: left;
            // border-style: solid;
            // border-radius: 5px;
            // border-left-width: 3px;
            // border-right-width: 4px;
            // border-bottom-width: 3px;
            // border-top-width: 1px;
            // border: 1px solid red;
        }
        div.title {
            width: 92%;
            // background-color: #e9e9e9;
            // min-height: 50px;
            // max-height: 50px;
            overflow-y: scroll;
            margin-top: 10px;
            border-color: #f1f1f1;
            padding: 5px;
            text-align: left;
            border-style: solid;
            border-radius: 5px;
            border: 1px solid #B26D94;
        }
        div.detailText {
            width: 92%;
            background-color: #e9e9e9;
            min-height: 50px;
            max-height: 50px;
            overflow-y: scroll;
            margin-top: 10px;
            margin-left: 7px;
            border-color: #f1f1f1;
            padding: 5px;
            text-align: left;
            border-style: solid;
            border-radius: 5px;
            border-left-width: 3px;
            border-right-width: 4px;
            border-bottom-width: 3px;
            border-top-width: 1px;
            border: 1px solid grey;
        }
        img.avatar {
            margin-top: 10px !important;
            margin-left: auto;
            margin-right: auto;
            width: 80px !important;
            height: 80px !important;
            border-radius: 50%;
            border-width: 1px;
            border-color: #B26D94;
            border-style: solid;
            background-color: white;
            align-content: center;
            overflow: hidden;
        }
        div.username {
            margin-bottom: 0px !important;
            font-size: 13px;
            font-weight: 600;
            color: #36383b;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: keep-all;
            width: 100%;
        }
        span.userInfo {
            font-size: 11px;
            font-weight: 300;
            color: #36383b;
            margin-bottom: 15px;
            margin-top: 0px !important;
        }
        div.feeContainer {
            margin-top: 20px;
            margin-bottom: 5px;
            height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            div.feeTitleContainer {
                display: flex;
                flex-direction: column;
                margin-right: 10px;
                span {
                    text-align: start;
                    color: #36383b;
                    font-size: 11px;
                    font-weight: 300;
                }
            }
            div.feeValueContainer {
                display: flex;
                flex-direction: column;
                justify-items: flex-end;
                span {
                    text-align: start;
                    color: #36383b;
                    font-size: 11px;
                    font-weight: 300;
                }
            }
        }
        div.socialCommentContainer {
            margin-top: 10px;
            width: 90%;
            display: flex;
            flex-direction: row;
            position: relative;
            align-items: center;
            min-height: 40px;
            div.horizontalContainer {
                width: 100%;
                position: relative;
                display: flex;
                flex-direction: row;
                min-height: 30px;
                span.commentLabel {
                    justify-content: flex-start;
                    text-align: center;
                    text-justify: auto;
                    font-size: 13px;
                    font-weight: 600;
                    height: 18px;
                    color: #ffffff;
                    border-radius: 15px;
                    padding: 5px 10px 5px 10px;
                    background-color: #979797;
                    overflow: hidden;
                }
                div.socialContainer {
                    position: absolute;
                    align-items: flex-end;
                    justify-content: flex-end;
                    width: 40%;
                    right: 0;
                    display: flex;
                    top: -3px;
                }
            }
        }
        span.userComment {
            position: relative;
            margin-top: 10px;
            width: 90%;
            text-align: start;
            max-height: 55px;
            font-size: 12px;
            font-weight: 600;
            word-break: break-all;
            color: #36383b;
            overflow: scroll;
            white-space: pre-line;
        }
        span.userCommentOne {
            position: relative;
            margin-top: 10px;
            width: 90%;
            text-align: start;
            max-height: 55px;
            font-size: 14px;
            font-weight: bold;
            word-break: break-all;
            color: #36383b;
            overflow: scroll;
            white-space: pre-line;
        }
        div.functionContainer {
            width: 100%;
            display: flex;
            flex-direction: row;
            margin-top: 15px;
            justify-content: space-evenly;
            margin-bottom: 10px;
            div.functionItem {
                width: 80px;
                display: flex;
                flex-direction: row;
                img {
                    width: 40px;
                    height: 40px;
                }
                span {
                    text-align: center;
                    font-size: 13px;
                    color: #36383b;
                    font-weight: 600;
                }
            }
        }
    }
}
