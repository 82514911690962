#block-load-more {
    width: 100%;
    .block-load-more {
        margin: 10px auto;
        border-bottom: 3px solid #888;
        border-top: 3px solid #a6d837;
        border-top: 3px solid #a6d837;
        border-left: 3px solid #a6d837;
        border-right: 3px solid #888;
        border-right: 3px solid #a6d837;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        40% {
            transform: rotate(288deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

#blocklist {
    width: 375px;
    height: 675px;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    background-color: white;
    margin: 0px auto;
    position: relative;
    header {
        width: 100%;
        height: 40pt;
        background: #fff;
        border-bottom: 1px solid #d8d8d8;
        display: flex;
        flex-direction: row;
        h3 {
            width: 40%;
            float: left;
            line-height: 50px;
            height: 50px;
            font-size: 12px;
            font-weight: 300;
            margin: 0px;
            padding: 0px;
        }
        div.back {
            width: 30%;
            display: flex;
            align-items: center;
            justify-items: center;
            img {
                padding: 10px;
            }
        }
        div.right {
            text-align: right;
            padding-top: 5pt;
        }
        h3 {
            text-align: center;
            font-size: 18px;
        }
    }
    div.mainContainer {
        position: relative;
        background: #000;
        display: flex;
        flex-direction: column;
        #block-content {
            width: 100%;
            height: 627px;
            box-sizing: border-box;
            overflow-y: scroll;
            background-color: #f4f9fa;
            position: relative;
            div.exception {
                width: 100%;
                height: 100%;
                justify-content: center;
                span {
                    font-size: 20px;
                    color: #36383b;
                }
            }
            div.item {
                background-color: #fff;
                padding: 16px;
                height: 50px;
                margin-bottom: 10px;
                position: relative;
                img.blockAvatar {
                    width: 50px;
                    float: left;
                    height: 50px;
                    margin-right: 15px;
                    border-radius: 50%;
                    background-color: white;
                }
                span {
                    font-size: 14px;
                    font-weight: 600;
                    color: #36383b;
                    width: 80%;
                    display: inline-block;
                    max-width: 220px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                span:hover {
                    cursor: default;
                }
                img.iconBlock {
                    width: 40px;
                    float: right;
                    height: 50px;
                    cursor: pointer;
                }
                img.iconBack:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
