#rootContainer {
    div.detailContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        background: #ffffff;
        width: 100%;
        border-radius: 10px;
        vertical-align: middle;
        img.avatar {
            margin-top: -30px;
            margin-left: auto;
            margin-right: auto;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            border-width: 1px;
            border-color: #f0f0f0;
            border-style: solid;
            background-color: white;
            align-content: center;
            align-items: center;
            overflow: hidden;
        }
        span.username {
            margin-top: 15px;
            font-size: 13px;
            font-weight: 600;
            color: #36383b;
        }
        span.userInfo {
            margin-top: 15px;
            font-size: 11px;
            font-weight: 300;
            color: #36383b;
        }
        div.addFriendContainer {
            position: absolute;
            display: flex;
            flex-direction: column;
            right: 25px;
            top: 15px;
            cursor: pointer;
            img {
                width: 55px;
                height: 55px;
            }
            span.addFriendText {
                font-size: 13px;
                color: #36383b;
                font-weight: 600;
            }
        }
        div.feeContainer {
            margin-top: 20px;
            margin-bottom: 5px;
            height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            div.feeTitleContainer {
                display: flex;
                flex-direction: column;
                margin-right: 10px;
                span {
                    text-align: start;
                    color: #36383b;
                    font-size: 11px;
                    font-weight: 300;
                }
            }
            div.feeValueContainer {
                display: flex;
                flex-direction: column;
                justify-items: flex-end;
                span {
                    text-align: start;
                    color: #36383b;
                    font-size: 11px;
                    font-weight: 300;
                }
            }
        }
        div.socialCommentContainer {
            margin-top: 10px;
            width: 90%;
            display: flex;
            flex-direction: row;
            position: relative;
            align-items: center;
            min-height: 40px;
            div.horizontalContainer {
                width: 100%;
                position: relative;
                display: flex;
                flex-direction: row;
                min-height: 30px;
                span.commentLabel {
                    justify-content: flex-start;
                    text-align: center;
                    text-justify: auto;
                    font-size: 13px;
                    font-weight: 600;
                    height: 18px;
                    color: #ffffff;
                    border-radius: 15px;
                    padding: 5px 10px 5px 10px;
                    background-color: #979797;
                    overflow: hidden;
                }
                div.socialContainer {
                    position: absolute;
                    align-items: flex-end;
                    justify-content: flex-end;
                    width: 40%;
                    right: 0;
                    display: flex;
                    top: -3px;
                }
            }
        }
        span.userComment {
            position: relative;
            margin-top: 10px;
            width: 90%;
            text-align: start;
            max-height: 55px;
            font-size: 12px;
            font-weight: 600;
            word-break: break-all;
            color: #36383b;
            overflow: scroll;
        }
        div.functionContainer {
            width: 100%;
            display: flex;
            flex-direction: row;
            margin-top: 15px;
            justify-content: space-evenly;
            margin-bottom: 10px;
            div.functionItem {
                width: 80px;
                display: flex;
                flex-direction: column;
                img {
                    width: 100%;
                    height: 80px;
                }
                span {
                    margin-top: -5px;
                    text-align: center;
                    font-size: 13px;
                    color: #36383b;
                    font-weight: 600;
                }
            }
        }
        div.padding {
            height: 20px;
        }
    }
}
